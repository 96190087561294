




















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

@Component({})
export default class GroupInvoiceReminderCustomers extends Vue {
  private isLoading = false
  private isSending = false
  private data = []
  private searchQuery = ''
  private sort = ''
  private hideNotDue = false
  private sumBalanceFormatted = ''
  private attachment = 'pdf'

  private defaults = {
    searchQuery: '',
    sort: 'payerName asc',
    hideNotDue: false,
  }

  private sorts = []

  private created() {
    this.sorts = [
      { id: 'payerName asc', name: this.$t('Customer name') },
      { id: 'payerCustomerNumber asc', name: this.$t('Customer number') },
      { id: 'oldestDueDate asc', name: this.$t('Oldest due date') },
      { id: 'maxReminderCount desc', name: this.$t('Most reminders') },
    ]

    this.resetToDefaults()

    if (this.$route.query?.search) {
      this.searchQuery = this.$route.query.search as string
    }
    if (this.$route.query?.sort) {
      this.sort = this.$route.query.sort as string
    }
    if (this.$route.query?.hideNotDue) {
      this.hideNotDue = true
    }

    this.load()
  }

  private resetToDefaults() {
    this.searchQuery = this.defaults.searchQuery
    this.sort = this.defaults.sort
    this.hideNotDue = this.defaults.hideNotDue
  }

  private clickReset() {
    this.resetToDefaults()
    this.clickSubmit()
  }

  private clickSubmit() {
    console.log('submit')
    this.$router.push({
      name: 'Payment/GroupInvoiceReminders/Customers',
      query: { search: this.searchQuery, sort: this.sort, ts: '' + new Date().getTime() },
    })
    this.load()
  }

  private load() {
    this.isLoading = true
    const sort = this.sort.split(' ')
    const url =
      '/v4/site/payment/invoice-reminders/group/balances?sortBy=' +
      sort[0] +
      '&sortDir=' +
      sort[1] +
      '&search=' +
      encodeURIComponent(this.searchQuery) +
      '&hideNotDue=' +
      (this.hideNotDue ? '1' : '')
    this.$axios
      .get(url)
      .then((response) => {
        this.data = []
        for (const item of response.data.data.customers) {
          item.selected = false
          this.data.push(item)
        }
        this.sumBalanceFormatted = response.data.data.balanceFormatted
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed loading customer balances')
        this.isLoading = false
      })
  }

  private selectAll() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].selected = true
    }
  }

  private selectNone() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].selected = false
    }
  }

  private selectInvert() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].selected = !this.data[i].selected
    }
  }

  private getSelection() {
    const result = []
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].selected) {
        result.push(this.data[i].payerId)
      }
    }
    return result
  }

  private clickSendReminders() {
    this.isLoading = true
    this.isSending = true
    this.$axios
      .post('/v4/site/payment/invoice-reminders/group/send-many', {
        payerIds: this.getSelection(),
        attachment: this.attachment,
      })
      .then((response) => {
        this.isLoading = false
        this.isSending = false
        let message = 'Unknown response status, not sure which reminders were sent'
        switch (response.data.data.status) {
          case 'AllSuccess':
            message = 'All reminders were successfully sent'
            break
          case 'AllError':
            message = 'Errors occurred, no reminders were sent'
            break
          case 'Partial':
            message = 'Some errors occurred, some reminders were sent, some were not'
            break
        }
        alert(message)
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed sending reminders')
        this.isLoading = false
        this.isSending = false
      })
  }

  private get sendButtonLabel() {
    const size = this.getSelection().length
    if (size === 0) {
      return ''
    } else if (size === 1) {
      return this.$t('Send reminder to 1 customer')
    } else {
      return this.$t('Send reminders to %s customers').replace('%s', '' + size)
    }
  }

  private get isSendButtonVisible() {
    return this.getSelection().length > 0
  }

  private getDetailsLink(item) {
    return {
      name: 'Payment/GroupInvoiceReminders/Customer',
      params: { id: item.payerId },
      query: { hideNotDue: this.hideNotDue ? '1' : '' },
    }
  }
}
